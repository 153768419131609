import { buildURL, rootAPI, callAPI } from "api/callAPI";
import React, { useState, useEffect, useRef } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PromptsQuerySelectors from "customizedComponents/PromptQuerySelectors";
import PublicationsFeed from "layouts/tenders/TendersFeed"; 

const TendersFeed = () => {
  const [loading, setLoading] = useState(false);
  const cache = useRef({});
  const [publicatons, setPublications] = useState([]);
  const [filteredDataState, setFilteredDataState] = useState([]); // New state for filtered data
  const [displayedData, setDisplayedData] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate(); // Access the navigate function here
  const sortingOptionList = [
    { criteria: "date", label: "Date" },
    { criteria: "relevanceScore", label: "Relevance" },
  ];
  const [filterState, setFilterState] = useState(() => {
    const savedFilters = localStorage.getItem("tendersFilters");
    return {
      filters: JSON.parse(savedFilters) || {
        startDate: "",
        endDate: "",
        relevanceScore: [0, 1],
        value: [0, 100000000],
        currency: ['EUR']

      },
      metadata: {
        startDate: { type: "date", description: "Start Date" },
        endDate: { type: "date", description: "End Date" },
        relevanceScore: { type: "slider", description: "Relevance Score", min: 0, max: 1, step: 0.01 },
        value: { type: "slider", description: "Estimated Value", min: 0, max: 1000000, step: 500 },

      }
    }
  }
);

  const fetchTenders = async (queryID) => {
      setLoading(true);
      let pubData;
      if (!cache.current[queryID]) {
        try {
          const data = await callAPI(
            buildURL(rootAPI, `extra/tenders?queryID=${queryID}`),
            "GET",
            null,
            navigate
          );
          pubData = await data.response;
          cache.current[queryID] = pubData;
        } catch (error) {
          console.error("Error fetching data:", error);
          pubData = [];
        } finally {
          setLoading(false);
        }
      } else {
        pubData = cache.current[queryID];
        setLoading(false);
      }
      setPublications(pubData);
  

  };
  
  const applyFilters = () => {
    let data = [...publicatons];
    const { filters, metadata } = filterState;

    Object.entries(filters).forEach(([key, value]) => {
      if (!value || (Array.isArray(value) && value.length === 0)) return;

      switch (metadata[key]?.type) {
        case "date":
          const date = new Date(value || "");
          data = data.filter((item) => {
            const itemDate = item.date ? new Date(item.date) : null;
            const feedbackStartDate = item.feedbackPeriodStart ? new Date(item.feedbackPeriodStart) : null;
            const feedbackEndDate = item.feedbackPeriodEnd ? new Date(item.feedbackPeriodEnd) : null;
        
            // If item.date is available, use the original filtering logic
            if (itemDate) {
              return (
                (key === "startDate" && itemDate >= date) ||
                (key === "endDate" && itemDate <= date)
              );
            } 
        
            // If item.date is not available, check if date falls between feedbackPeriodStart and feedbackPeriodEnd
            if (feedbackStartDate && feedbackEndDate) {
              return (
                (key === "startDate" && feedbackStartDate <= date) ||
                (key === "endDate" && feedbackEndDate >= date)
              );
            }
        
            // If neither date nor feedbackPeriodStart/feedbackPeriodEnd are available, don't include the item
            return false;
          });
          break;

        case "autocomplete":
          data = data.filter((item) =>
            value.some((val) => item[key]?.toLowerCase().includes(val.toLowerCase()))
          );
          break;

        case "slider":
          const [min, max] = value;
          data = data.filter(
            (item) => (item[key] || 0) >= min && (item[key] || 0) <= max
          );
          break;

        default:
          break;
      }
    });

          // Sorting logic
    switch (sortCriteria) {
      case "date":
        data.sort((a, b) => {
          const dateA = new Date(a.date || a.feedbackPeriodStart || 0);
          const dateB = new Date(b.date || b.feedbackPeriodStart || 0);
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });
        break;
      case "relevanceScore":
        data.sort((a, b) =>
          sortOrder === "asc"
            ? (a.relevanceScore || 0) - (b.relevanceScore || 0)
            : (b.relevanceScore || 0) - (a.relevanceScore || 0)
        );
        break;
      default:
        break;
        
    }

    setFilteredDataState(data);

    // Pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    setDisplayedData(data.slice(startIndex, startIndex + itemsPerPage));
  };

  useEffect(() => {
    applyFilters();
    localStorage.setItem("tendersFilters", JSON.stringify(filterState.filters));

  }, [publicatons, filterState, sortCriteria, sortOrder, currentPage]);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PromptsQuerySelectors
          onQueryUpdate={fetchTenders}
          filterState={filterState}
          setFilterState={setFilterState}
          handleSort={handleSort}
          sortCriteria={sortCriteria}
          sortOrder={sortOrder}
          sortingOptionList={sortingOptionList}

        />
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        {/* Feed Data */}
        <PublicationsFeed
          loading={loading}
          data={displayedData}
          setCurrentPage={setCurrentPage}
        />

        {/* Pagination */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={Math.ceil(filteredDataState.length / itemsPerPage)} // Use filteredDataState for pagination
            page={currentPage}
            onChange={(e, value) => setCurrentPage(value)}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TendersFeed;
