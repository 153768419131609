/**
=========================================================
* Kilner Tech Dashboard
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Kilner Tech Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Kilner Tech Dashboard React layouts
import Tables from "layouts/tables";
import PromptsManager from "layouts/admin";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import SignIn from "layouts/authentication/sign-in";
import GradeSheets from "layouts/gradeSheets";
import Chatbot from "layouts/chatbot";
import Feed from "layouts/feed";
import Publications from "layouts/publications";
import TendersFeed from "layouts/tenders";

// @mui icons
import Icon from "@mui/material/Icon";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
const routes = [
  {
    type: "collapse",
    name: "MEP Interventions",
    key: "interventions",
    icon: <ViewListOutlinedIcon style={{ color: "black" }} />,
    route: "/interventions",
    component: <Feed />,
  },
  {
    type: "collapse",
    name: "Publications",
    key: "publications",
    icon: <ArticleTwoToneIcon style={{ color: "black" }} />,
    route: "/publications",
    component: <Publications />,
  },
  {
    type: "collapse",
    name: "Tenders",
    key: "tenders",
    icon: <ArticleTwoToneIcon style={{ color: "black" }} />,
    route: "/tenders",
    component: <TendersFeed />,
  },
  {
    type: "collapse",
    name: "Chat Bot",
    key: "chatbot",
    icon: <ChatBubbleIcon style={{ color: "black" }} />,
    route: "/chatbot",
    component: <Chatbot />,
  },
  {
    type: "collapse",
    name: "MEP Info",
    key: "tables",
    icon: (
      <Icon fontSize="small" color="#000 !important" style={{ color: "black" }}>
        person
      </Icon>
    ),
    route: "/MEP-Info",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "MEP League Tables",
    key: "gradeSheets",
    icon: <SummarizeIcon style={{ color: "black" }} />,
    route: "/MEP-League-Tables",
    component: <GradeSheets />,
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: (
      <AdminPanelSettingsTwoToneIcon
        fontSize="small"
        style={{ color: "black" }}
      />
    ),
    route: "/admin",
    component: <PromptsManager style={{ color: "black" }} />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
